var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-breadcrumbs',{staticClass:"grey lighten-2 white--text",attrs:{"items":_vm.breadcrumbs}}),_c('div',{staticClass:"pa-4 grey lighten-4"},[_c('div',{staticClass:"d-flex align-center justify-space-between mb-4"},[_c('p',{staticClass:"subtitle-1 ma-0"},[_vm._v(" Escolha um Modelo abaixo para editar ou excluir. ")]),_c('v-btn',{attrs:{"small":"","depressed":"","color":"fmq_gray","dark":"","to":"/banco-de-cartas-tr-criar"}},[_vm._v("Novo Modelo TR")])],1),_c('ModelosTabela',{attrs:{"headers":_vm.headers,"itens":_vm.itens,"total":_vm.total,"pageText":_vm.pageText,"pageNumber":_vm.page,"loading":_vm.loading,"isTr":true,"visualizarRoute":"BancoDeCartasTRVisualizar","editarRoute":"BancoDeCartasTREditar"},on:{"handleFilter":_vm.filter,"excluir":_vm.excluirModal},scopedSlots:_vm._u([{key:`produtos`,fn:function(){return [_c('v-autocomplete',{attrs:{"items":_vm.listProdutos,"hide-no-data":"","hide-selected":"","item-text":"nome","item-value":"id","placeholder":"Busque pelo produto","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.page = 1;
            _vm.buscar(_vm.formData);}},model:{value:(_vm.formData.produtosId),callback:function ($$v) {_vm.$set(_vm.formData, "produtosId", $$v)},expression:"formData.produtosId"}})]},proxy:true},{key:`categorias`,fn:function(){return [_c('v-autocomplete',{attrs:{"items":_vm.listCategorias,"hide-no-data":"","hide-selected":"","item-text":"nome","item-value":"id","placeholder":"Busque pelo produto","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.page = 1;
            _vm.buscar(_vm.formData);}},model:{value:(_vm.formData.categoriasId),callback:function ($$v) {_vm.$set(_vm.formData, "categoriasId", $$v)},expression:"formData.categoriasId"}})]},proxy:true},{key:"titulo",fn:function(){return [_c('v-text-field',{attrs:{"placeholder":"Busque pelo titulo","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.page = 1;
            _vm.buscar(_vm.formData);}},model:{value:(_vm.formData.titulo),callback:function ($$v) {_vm.$set(_vm.formData, "titulo", $$v)},expression:"formData.titulo"}})]},proxy:true},{key:"tags",fn:function(){return [_c('v-autocomplete',{attrs:{"items":_vm.listaDeTags,"hide-no-data":"","hide-selected":"","item-text":"nome","item-value":"id","placeholder":"Busque pela tag","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.page = 1;
            _vm.buscar(_vm.formData);}},model:{value:(_vm.formData.tagsId),callback:function ($$v) {_vm.$set(_vm.formData, "tagsId", $$v)},expression:"formData.tagsId"}})]},proxy:true},{key:`autor.nome`,fn:function(){return [_c('v-text-field',{attrs:{"solo-inverted":"","flat":"","dense":"","hide-details":true,"disabled":"","readonly":""}})]},proxy:true},{key:"id",fn:function(){return [_c('div',{staticClass:"d-flex justify-start"},[_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","x-small":"","fab":"","color":"red"},on:{"click":_vm.clear}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-btn',{attrs:{"outlined":"","x-small":"","fab":"","color":"blue"},on:{"click":_vm.search}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1)]},proxy:true}],null,true)}),_c('AlertConfirmation',{attrs:{"icon":_vm.icon,"colorIcon":_vm.colorIcon,"dialog":_vm.dialog,"dialogMessage":_vm.dialogMessage},on:{"close":function($event){_vm.dialog = false},"accept":_vm.excluir}}),_c('AlertSuccess',{attrs:{"dialog":_vm.success,"dialogMessage":_vm.messageSuccessful},on:{"close":function($event){_vm.buscar(_vm.formData);
        _vm.success = false;
        _vm.clear();}}}),_c('AlertError',{attrs:{"alertError":_vm.error,"messageError":_vm.messageError}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }